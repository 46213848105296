import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {headerTitle, mainRouterGuard} from '@shared/utils';
import {ZbAuthCheckerService} from '@zibanu/auth';
import {SETTING_ROUTE_LIST} from './settings/settings.routes';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    title: headerTitle(),
    path: 'home',
    loadComponent: () => import('./home/home-content'),
  },
  {
    title: headerTitle('Settings'),
    path: 'settings',
    children: [...SETTING_ROUTE_LIST],
    canActivate: [mainRouterGuard],
    providers: [ZbAuthCheckerService],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
