/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Jhoana Marcela Rodriguez Fernandez
* Date:         5/3/2024 - 11:40
* Module name:  frontend
* File name:    settings.routes.ts
* IDE:          WebStorm
*/

import {Routes} from '@angular/router';
import SettingsPanelComponent from './panel';
import {MkadContractsService, MkadCustomersService} from '@shared/services';
import {headerTitle} from '@shared/utils';

export const SETTING_ROUTE_LIST: Routes = [
  {path: '', redirectTo: 'panel', pathMatch: 'prefix'},
  {path: 'panel', component: SettingsPanelComponent},
  {
    path: 'customers',
    title: headerTitle('Customers'),
    loadComponent: () => import('./customer/customers/customers-list'),
    providers: [MkadCustomersService]
  },
  {
    path: 'contracts',
    title: headerTitle('Contracts'),
    loadComponent: () => import('./customer/contracts/contracts-list'), providers: [MkadContractsService]
  },
  {
    path: 'contacts',
    title: headerTitle('Contacts'),
    loadComponent: () => import('./customer/contacts/contacts-list'), providers: []
  },
  {
    path: 'groups',
    title: headerTitle('Group'),
    loadComponent: () => import('./customer/groups/groups-list')
  },
  {
    path: 'users',
    loadComponent: () => import('./customer/users/users-list')
  },
  {
    path: 'locations',
    loadComponent: () => import('./customer/locations/locations-list')
  },
  {
    path: 'sensors',
    loadComponent: () => import('./system/sensors/sensors-list')
  },
  {
    path: 'parameters',
    loadComponent: () => import('./system/parameters/parameters-list')
  },
  {
    path: 'commands',
    loadComponent: () => import('./system/commands/commands-list')
  },
  {
    path: 'icons',
    loadComponent: () => import('./system/icons/icons-list')
  },
  {
    path: 'device-model',
    loadComponent: () => import('./system/device-model/device-model-list')
  },
  {
    path: 'devices',
    loadComponent: () => import('./system/devices/devices-list')
  },
  {
    path: 'triggers',
    loadComponent: () => import('./device/triggers/triggers-list')
  },
  {
    path: 'device-customization',
    loadComponent: () => import('./device/customer-device/customer-device-list')
  },
  {path: '**', redirectTo: 'panel', pathMatch: 'prefix'},
];
